// Generated by Framer (a7ecdf4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/gmY5winel";

const enabledGestures = {PZni5sfSN: {hover: true}};

const cycleOrder = ["PZni5sfSN"];

const serializationHash = "framer-glnNG"

const variantClassNames = {PZni5sfSN: "framer-v-1w9ztk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, OekSpeLvX: title ?? props.OekSpeLvX ?? "Buy Now", rpeFizXLM: link ?? props.rpeFizXLM} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, OekSpeLvX, rpeFizXLM, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "PZni5sfSN", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Link href={rpeFizXLM} openInNewTab><motion.a {...restProps} animate={variants} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1w9ztk", className, classNames)} framer-12sksko`} data-framer-name={"Default"} initial={variant} layoutDependency={layoutDependency} layoutId={"PZni5sfSN"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-40d00293-b1f9-4c64-8510-ff66aa40779b, rgb(157, 255, 184))", borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopLeftRadius: 40, borderTopRightRadius: 40, ...style}} variants={{"PZni5sfSN-hover": {backgroundColor: "var(--token-14f1ca5f-935c-4605-8cb3-8f1d33cf85c4, rgb(40, 40, 40))"}}} {...addPropertyOverrides({"PZni5sfSN-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1czw7wh"} data-styles-preset={"gmY5winel"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-dc6f3470-7908-4d05-b3d7-52bb1007cf0f, rgb(255, 255, 255)))"}}>Buy Now</motion.p></React.Fragment>} className={"framer-1td1q4s"} data-framer-name={"Get started"} layoutDependency={layoutDependency} layoutId={"iyeRe4hPG"} style={{"--extracted-r6o4lv": "var(--token-dc6f3470-7908-4d05-b3d7-52bb1007cf0f, rgb(255, 255, 255))", "--framer-paragraph-spacing": "0px"}} text={OekSpeLvX} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</LayoutGroup>)

});

const css = [".framer-glnNG[data-border=\"true\"]::after, .framer-glnNG [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-glnNG.framer-12sksko, .framer-glnNG .framer-12sksko { display: block; }", ".framer-glnNG.framer-1w9ztk { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: visible; padding: 12px 28px 12px 28px; position: relative; text-decoration: none; width: 309px; }", ".framer-glnNG .framer-1td1q4s { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-glnNG.framer-1w9ztk { gap: 0px; } .framer-glnNG.framer-1w9ztk > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-glnNG.framer-1w9ztk > :first-child { margin-left: 0px; } .framer-glnNG.framer-1w9ztk > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 309
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"BMgDiNSaN":{"layout":["fixed","auto"]}}}
 * @framerVariables {"OekSpeLvX":"title","rpeFizXLM":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerlbFZEHf7v: React.ComponentType<Props> = withCSS(Component, css, "framer-glnNG") as typeof Component;
export default FramerlbFZEHf7v;

FramerlbFZEHf7v.displayName = "Buy Now Button";

FramerlbFZEHf7v.defaultProps = {height: 200, width: 309};

addPropertyControls(FramerlbFZEHf7v, {OekSpeLvX: {defaultValue: "Buy Now", displayTextArea: false, title: "Title", type: ControlType.String}, rpeFizXLM: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerlbFZEHf7v, [...sharedStyle.fonts])